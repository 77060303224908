import React, { useState } from "react"
import { Form, Formik, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import axios from "axios"
import isEnded from "../utils/isEnded"
import Input from "../Components/Input"
import Layout from "../Components/Layout"
import Box from "../Components/Box2"
import { RaisedButton } from "../Components/Buttons"
import StartIlliustration from "../assets/images/start-illiustration.jpg"

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Neteisingas el.pašto formatas")
    .required("Laukas yra privalomas"),
})

export default () => {
  const [isSucces, setIsSuccess] = useState(false)
  const onSubmit = (values, { setFieldError }) => {
    axios
      .post(
        "https://aplinkosaugosegzaminas.lrt.lt/olimpiada/api/begin",
        new URLSearchParams({
          email: values.email,
        }).toString()
      )
      .then(({ data }) => {
        if (data.status === "error") {
          setFieldError("email", data.message)
        } else {
          setIsSuccess(data.message)
        }
      })
      .catch(() => {
        // setIsLoading(false)
      })
  }

  return (
    <Layout>
      <div className="start-page">
        <Box>
          <h1>Pradėti ekonomikos viktoriną {/*isEnded() ? "Pasitikrink atsakymus" : "Pradėti MOlimpiadą"*/}</h1>

          {!isSucces && (
            <>
              <p>
                Įvesk savo el. pašto adresą, kuriuo registravaisi į ekonomikos viktoriną, ir mes tau
                atsiųsime asmeninę dalyvavimo nuorodą
              </p>

              {/*isEnded() && (
                <p>
                  Įvesk savo el. pašto adresą, kuriuo registravaisi, ir mes tau
                  atsiųsime asmeninę nuorodą, kur tu galėsi pasitikrinti
                  atsakymus.
                </p>
              )*/}
              {/*!isEnded() && (
                <p>
                  Įvesk savo el. pašto adresą, kuriuo registravaisi, ir mes tau
                  atsiųsime asmeninę dalyvavimo nuorodą.
                </p>
              )*/}

              <Formik
                validationSchema={validationSchema}
                initialValues={{
                  email: "",
                }}
                onSubmit={onSubmit}
                component={() => (
                  <Form>
                    <Field
                      component={Input}
                      type="text"
                      placeholder=" "
                      required
                      name="email"
                      label="El. paštas"
                    />
                    <ErrorMessage name="email"></ErrorMessage>

                    <br/>
                    <br/>

                    <RaisedButton type="submit">DALYVAUTI</RaisedButton>
                  </Form>
                )}
              />

              <p>
                Jeigu laiško vis dar nerandi, patikrinki el. pašto šiukšlių dėžutėje.
              </p>
            </>
          )}

          {isSucces && <p>{isSucces}</p>}
        </Box>
      </div>

      {/*
      <div className="tac">
        <img src={StartIlliustration} alt="" />
      </div>
      */}
    </Layout>
  )
}
